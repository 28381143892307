.container-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* gap: 10%; */

  width: 100%;

  /* background-color: yellowgreen; */
}

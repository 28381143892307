.container-content a {
  text-decoration: none;
}

.button-download {
  margin: 4rem auto;
  font-size: var(--fs-small-text);
}

.major {
  text-align: center;
  font-size: var(--fs-big-text);
  font-weight: 600;
}

.school {
  margin-top: 0.5rem;
  text-align: center;
  font-size: var(--fs-mid-text);
  font-weight: 300;
}

.container-headers {
  margin-top: 5rem;
  display: flex;
  align-items: center;

  text-align: center;
  font-weight: 700;
  font-size: var(--fs-mid-text);
}

.container-headers .class {
  width: 60%;
}

.container-headers .grade {
  width: 20%;
}

.container-headers .credits {
  width: 20%;
}

.bottom-line {
  height: 0.15rem;
  background-color: var(--main-border-color);
  border: 0;
}

/**************************/
/* BELOW 544px /  phones */
/**************************/
@media (max-width: 34em) {
  .container-headers .class {
    width: 50%;
  }

  .container-headers .grade {
    width: 25%;
  }

  .container-headers .credits {
    width: 25%;
  }
}

.container-project-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  margin-top: 4rem;
  height: 300px;
  /* width: 47.5%; */
  width: 45%;

  overflow: hidden;
  /* border: var(--main-border); */
  border-radius: var(--main-border-radius);

  text-decoration: none;
  color: var(--white-color);
}

.container-project-card img {
  /* width: 100%; */
  object-fit: cover;

  height: 85%;
}

.container-project-card .container-text {
  width: 100%;
  height: 15%;

  /* border: var(--main-border); */
  /* border-radius: var(--main-border-radius); */
  text-align: center;
  font-size: var(--fs-mid-small-text);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1000;
  transition: all 0.2s ease-in;
}

.container-project-card:hover .container-text {
  background-color: #292929;
}

/**************************/
/* BELOW 700px /  smaller tablets */
/**************************/
@media (max-width: 44em) {
  .container-project-card {
    width: 100%;
  }
}

/**************************/
/* BELOW 375px /  phones */
/**************************/
@media (max-width: 23em) {
  .container-project-card {
    height: 250px;
  }
}

.container-project {
  /* background-color: yellowgreen; */
}

.date {
  margin-top: 0.5rem;
  font-size: var(--fs-small-text);
}

.container-subtitles {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 3rem 0;
}

.container-subtitles .subtitle {
  color: #34e4ea;
  background: rgba(52, 228, 234, 0.1);
}

.paragraph {
  font-size: var(--fs-mid-small-text);
}

.container-bullets {
  margin-top: 1rem;
}

.container-bullets li {
  margin-left: 3rem;
  margin-top: 0rem;

  font-size: var(--fs-mid-small-text);
  padding: 0.3rem 0;
}

.container-links {
  margin: var(--margin-up-down) 0;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--fs-small-text);
}

.container-links .container-link {
  display: block;
  margin: 1.5rem 0;
  margin-right: 2rem;
}

.container-links .container-link:focus {
  text-decoration: none;
}

.container-links .container-link .link {
  color: #d1e231;
  background: rgba(209, 226, 49, 0.1);
  transition: all 0.1s ease-in;
}

.container-links .container-link .link:active {
  text-decoration: none;
}

.container-links .container-link .link:hover {
  background: rgba(209, 226, 49, 0.15);
}

.container-links a.container-link:visited {
  background-color: yellowgreen;
  text-decoration: none;
  /* font-size: 48px; */
}

.container-links .link-github {}

.container-links .link-youtube {}

.container-links .link-page {}




.container-imgs {
  width: 100%;
  margin-top: 2.5rem;
  padding-bottom: 2.5rem;

  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 2.5rem;
}

.container-imgs img {
  /* width: 100%; */
  width: 85%;
  border-radius: var(--sec-border-radius);
}



/**************************/
/* BELOW 700px /  smaller tablets */
/**************************/
@media (max-width: 44em) {
  .container-imgs img {
    width: 100%;
  }
}
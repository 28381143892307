.container-profile-ext {
  /* width: 34%; */
  /* background-color: violet; */
}

.container-profile-ext .container-profile {
  width: 32rem;
  /* width: 100%; */
  position: sticky;
  position: -webkit-sticky;
  top: 2rem;
}

.container-persona-info {
  /* height: 25rem; */

  height: auto;
  padding: 3rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: var(--main-border-radius);
  border: 1px solid var(--main-border-color);
  background-color: var(--main-bg-color);
}

.container-persona-info img {
  width: 90px;
  border-radius: 100%;

  border: 1px solid #2d2d2d;
}

.container-persona-info h1 {
  width: 80%;
  font-size: var(--fs-big-text);
  font-weight: 600;
  text-align: center;

  margin-top: 2rem;
  margin-bottom: 1rem;
}

.container-persona-info h2 {
  width: 90%;
  font-size: var(--fs-mid-text);
  font-weight: 300;
  text-align: center;
}

.container-social-networks {
  /* margin: var(--margin-up-down) 0; */
  margin: 2rem 0;
  /* height: 7.5rem; */
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;

  border-radius: 1.8rem;
  border: 1px solid var(--main-border-color);
}

.container-social-networks .container-icon {
  width: 4.2rem;
  height: 4.2rem;

  border-radius: 100%;
  border: 1px solid var(--main-border-color);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.2s ease-in;
}

.container-social-networks .container-icon:hover {
  background-color: #292929;
}

.container-social-networks .icon-social-network {
  width: 50%;
  height: 50%;
}

.container-skills {
  border-radius: 1.8rem;
  border: 1px solid var(--main-border-color);

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 1rem;
}

.container-skills .separator {
  width: 90%;
  height: 0.05rem;
  border: none;
  color: var(--main-border-color);
  background-color: var(--main-border-color);
  margin: 1rem 0;
}

.container-skills span {
  margin: 0.5rem 0.5rem;
  padding: 0.75rem 1rem;
  font-size: var(--fs-small-small-text);
}

/**************************/
/* BELOW 1200px /landscape tablets */
/**************************/
@media (max-width: 75em) {
  .container-profile-ext .container-profile {
    width: 100%;
  }

  .container-persona-info {
    height: auto;
    padding: 3rem 0;
  }

  .container-skills span {
    font-size: var(--fs-small-text);
  }
}

/**************************/
/* BELOW 940px /  tablets */
/**************************/
@media (max-width: 59em) {
  /* .container-profile-ext .container-profile {
    position: relative;
  } */
}

/**************************/
/* BELOW 544px /  phones */
/**************************/
@media (max-width: 34em) {
  .container-persona-info img {
    width: 75px;
  }
}

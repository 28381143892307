.button {
  font-family: "Montserrat", sans-serif;
  display: block;
  /* margin: 4rem auto; */
  padding: 1.25rem 2.25rem;
  font-size: var(--fs-mid-text);
  border-radius: var(--sec-border-radius);

  cursor: pointer;
  color: var(--white-color);

  background-color: var(--sec-bg-color);
  border: none;
  transition: all 0.2s ease-in;
}

.button:hover {
  background-color: #333333;
}

.container-education {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: var(--margin-up-down) 0;

  padding-left: 1.5rem;
}

.img-square {
  /* margin-left: 2rem; */
  margin-right: 1.5rem;
  width: 60px;
  height: 60px;
  object-fit: cover;
  /* border-radius: var(--main-border-radius); */
  border-radius: var(--sec-border-radius);
}

.title-experience {
  font-size: var(--fs-mid-text);
  font-weight: 600;
}

.subtitle-experience {
  font-size: var(--fs-mid-small-text);
  font-weight: 500;

  padding-bottom: 1rem;
}

.text-experience {
  font-size: var(--fs-small-text);
}

.bottom-line {
  height: 0.15rem;
  background-color: var(--main-border-color);
  border: 0;
}

/**************************/
/* BELOW 544px /  phones */
/**************************/
@media (max-width: 34em) {
  .container-experience {
    margin: 3rem 0;

    padding-left: 1.5rem;
  }

  .img-square {
    width: 45px;
    height: 45px;
  }
}

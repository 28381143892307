.table-semester {
  /* margin-top: 3rem; */
  margin: var(--margin-up-down) 0;
  width: 100%;
  font-size: var(--fs-small-text);
  /* background-color: yellowgreen; */
}

.table-title {
  width: 100%;
  font-size: var(--fs-mid-text);

  font-weight: 600;
  text-align: center;
  /* background-color: yellowgreen; */
  width: 100%;
  margin: 0 0;

  /* background-color: turquoise; */
}

.table-semester td {
  padding-top: 1.5rem;
  /* text-align: center; */
}

.table-subject {
  width: 60%;
}

.table-grade {
  font-weight: 600;
  width: 20%;
  text-align: center;
}

.table-credits {
  width: 20%;
  text-align: center;
}

/**************************/
/* BELOW 544px /  phones */
/**************************/
@media (max-width: 34em) {
  .table-subject {
    width: 50%;
  }

  .table-grade {
    width: 25%;
  }

  .table-credits {
    width: 25%;
  }
}
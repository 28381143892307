.header {
  /* width: 80rem; */
  width: 100%;
  padding: 1.5rem 0;
  /* height: 8rem; */

  background-color: var(--main-bg-color);
  border-radius: var(--main-border-radius);
  border: var(--main-border);
}

.header nav {
  width: 100%;
  height: 100%;
}

.header nav ul {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  /* gap: 5rem; */

  list-style: none;
}

.button {
  min-width: 15rem;
  margin: 0;
  padding: 1rem 2rem;
  font-size: var(--fs-mid-text);
  text-align: center;
}

.header nav ul > * {
  text-decoration: none;
  color: var(--white-color);
}

.header nav ul .link-active {
  background-color: #006dff;

  /* font-weight: 500; */
}

/**************************/
/* BELOW 700px /  smaller tablets */
/**************************/
@media (max-width: 44em) {
  .header nav ul {
    gap: 1rem;
  }
  .button {
    margin: 1rem 1rem;
    min-width: 13rem;
  }
}

.container-bullets {
  margin: var(--margin-up-down) 0;
}

.text-experience {
  margin-left: 2rem;
  /* margin-top: 1rem; */

  font-size: var(--fs-mid-small-text);
  padding: 0.3rem 0;
}

.text-experience-bullet {
  margin-left: 5rem;
  margin-top: 0rem;

  /* background-color: yellowgreen; */
}

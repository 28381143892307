.span {
  font-weight: 500;
  font-size: var(--fs-small-text);

  padding: 1rem 2rem;

  border-radius: var(--sec-border-radius);

  color: #158bcb;
  background: rgba(20, 129, 186, 0.075);



}

.span:visited {
  text-decoration: none;
}
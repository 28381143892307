.box-gradient-ext .box-gradient-int {
  /* position: absolute; */
  width: 100%;
  height: 100%;

  /* background: linear-gradient(
    90deg,
    rgba(249, 233, 0, 0.5) 0%,
    rgba(218, 65, 103, 0.5) 100%
  ); */

  background: linear-gradient(
    90deg,
    rgba(66, 0, 255, 0.5) 0%,
    rgba(226, 0, 56, 0.5) 100%
  );

  /* background: linear-gradient(90deg, #158bcb 0%, #34e4ea 100%); */
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 2.6rem;

  min-height: 110vh;
  max-width: 1200px;
  /* width: 100%; */
  margin: 0 auto;

  padding-top: 8rem;
  padding-bottom: 5rem;

  /* background-color: yellowgreen; */
}

.box-gradient-ext {
  position: absolute;
  width: 100%;
  height: 200px;
  background-color: #fff;
}

.container-content {
  width: 80rem;

  padding-left: 1.5rem;

  /* background-color: turquoise; */
}

/**************************/
/* BELOW 1200px /landscape tablets */
/**************************/
@media (max-width: 75em) {
  html {
    /* 9px / 16px */
    font-size: 56.25%;
  }

  .container {
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
    /* background-color: yellowgreen; */
  }

  .container-profile {
    width: 25%;
  }

  .container-content {
    width: 75%;
  }
}

/**************************/
/* BELOW 940px /  tablets */
/**************************/
@media (max-width: 59em) {
  html {
    /* 8px / 16px */
    font-size: 50%;
  }

  .container {
    width: 100%;
    flex-wrap: wrap;
    /* padding-left: 1.5rem; */
  }

  .container-profile {
    width: 100%;
  }

  .container-content {
    width: 100%;
    padding-left: 0;
    /* padding: 0 1.5; */
    /* padding: 0 1.5rem; */
  }
}

/**************************/
/* BELOW 700px /  smaller tablets */
/**************************/
@media (max-width: 44em) {
}

/**************************/
/* BELOW 544px /  phones */
/**************************/
@media (max-width: 34em) {
}

/**************************/
/* BELOW 375px /  phones */
/**************************/
@media (max-width: 23em) {
}

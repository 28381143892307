.container-content {
  /* width: 100%; */
  /* background-color: violet; */
  /* padding-bottom: 5rem; */
}

.container-content a {
  text-decoration: none;
}

.button-download {
  font-size: var(--fs-small-text);
  margin: 4rem auto;
  /* margin: 3rem auto; */

  /* background-color: yellowgreen; */
}

.title-section {
  font-size: var(--fs-subtitle);
  font-weight: 600;
  margin-top: 2rem;
}

.bottom-line {
  height: 0.15rem;
  background-color: var(--main-border-color);
  border: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/**************************/
/* GENERAL */
/**************************/
html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  color: #f3f3f3;
  line-height: 1.5;

  background-color: var(--main-bg-color);
}

:root {
  /* --fs-big-title: 5rem;
  --fs-title: 4.4rem;
  --fs-sub-title: 4rem; */

  --fs-title: 3rem;
  --fs-subtitle: 2.4rem;

  --fs-big-text: 2rem;
  --fs-mid-text: 1.8rem;
  --fs-mid-small-text: 1.6rem;
  --fs-small-text: 1.4rem;
  --fs-small-small-text: 1.2rem;

  --white-color: #f3f3f3;
  /* --main-bg-color: #1f1f1f; */
  --main-bg-color: #1c1c1c;
  --sec-bg-color: #292929;
  /* --main-border-color: #5f6368; */
  /* --main-border-color: rgba(95, 99, 104, 0.5); */
  --main-border-color: rgba(95, 99, 104, 0.5);

  --main-border-radius: 1.4rem;
  --sec-border-radius: 1rem;
  --main-border: 1px solid var(--main-border-color);

  --margin-up-down: 3rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.title-section {
  margin-top: 8rem;
  font-size: var(--fs-title);
  width: 100%;
}

/**************************/
/* BELOW 940px /  tablets */
/**************************/
@media (max-width: 59em) {
  .title-section {
    margin-top: 5rem;
  }
}
